import { Environment } from '../../../environment.interface';

export const environment: Environment = {
  production: true,
  scope: 'admin',
  platform: 'web',

  appVersion: '1.4.2',

  apiBaseUrl: 'https://api.begreen.africa/v1',
  deepLinkBaseUrl: 'https://app.begreen.africa/referraluser/',
  imageBaseUrl: 'https://shared.begreen.africa',
  socketIoUrl: 'https://api.begreen.africa',

  apiPageSize: 10,

  google: { maps: { key: 'AIzaSyCIZrmSGIfRO_208esZo3v2vJbyrsPV-yY' } },

  jwt: { refreshTokenNonce: 'A2UFZ8Jjxp' },

  publicUrl: 'admin.begreen.africa',
};
