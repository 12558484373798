import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BasketApi } from '@be-green/api-services';
import {
  BasketDto,
  BasketStatusCode,
  CollectionType,
  ProgramType,
  QualityCode,
  ValueLabelDto,
} from '@be-green/dto';
import { ErrorService, SeoService, UtilsService } from '@be-green/ui-services';
import { format } from 'date-fns';
import { FilterMetadata, LazyLoadEvent, MessageService } from 'primeng/api';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'be-green--admin--baskets-index',
  templateUrl: './baskets-index.component.html',
  styleUrls: ['./baskets-index.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BasketsIndexComponent implements OnInit {
  baskets!: BasketDto[];
  collectionTypes: ValueLabelDto[] = [
    { value: CollectionType.RealTime, label: 'Temps réel' },
    { value: CollectionType.Deferred, label: 'Différée' },
  ];
  filters: {
    [s: string]: FilterMetadata;
  } = {};
  first = 0;
  isLoading = false;
  programTypes: ValueLabelDto[] = [
    { value: ProgramType.GoodDeal, label: 'Bon plan' },
    { value: ProgramType.Pro, label: 'Pro' },
    { value: ProgramType.Recycling, label: 'Recyclage' },
  ];
  qualities: ValueLabelDto[] = [
    { value: QualityCode.Sad, label: '🙁' },
    { value: QualityCode.Neutral, label: '😐' },
    { value: QualityCode.Happy, label: '😊' },
  ];
  recordCount = 0;
  rows = 0;
  sortField = '';
  sortOrder = 1;
  statuses: ValueLabelDto[] = [
    { value: BasketStatusCode.Validated, label: 'Validé par utilisateur' },
    {
      value: BasketStatusCode.Deposited,
      label: 'Déposé par utilisateur (Pro)',
    },
    { value: BasketStatusCode.Scanned, label: 'Scanné' },
    { value: BasketStatusCode.Collected, label: 'Collecté (compacteurs)' },
    {
      value: BasketStatusCode.Approved,
      label: 'Approuvé par niveau supérieur',
    },
    { value: BasketStatusCode.Expired, label: 'Éxpiré' },
  ];

  constructor(
    @Inject('API_PAGE_SIZE') readonly apiPageSize: number,
    private readonly activatedRoute: ActivatedRoute,
    private readonly errorService: ErrorService,
    private readonly basketApi: BasketApi,
    private readonly layoutService: LayoutService,
    private readonly location: Location,
    private readonly messageService: MessageService,
    private readonly router: Router,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'QR codes' }]);
    this.seoService.setTitle('QR codes - Admin - Be Green');
  }

  ngOnInit(): void {
    this.isLoading = true;

    const { filters, first, rows, sortField, sortOrder } =
      UtilsService.fromQueryParamMapToLazyLoadEvent(
        this.activatedRoute.snapshot.queryParamMap,
        this.apiPageSize,
      );

    this.filters = filters;
    this.first = first;
    this.rows = rows;
    this.sortField = sortField;
    this.sortOrder = sortOrder;

    this.fetchNextPage({ filters, first, rows, sortField, sortOrder });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asBasketDto(basket: any): BasketDto {
    return basket as BasketDto;
  }

  eventTargetValue(event: Event) {
    return (event.target as HTMLInputElement).value;
  }

  export() {
    this.isLoading = true;

    this.basketApi.export().subscribe({
      next: async (blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.setAttribute('style', 'display: none');
        link.href = url;

        const filename = UtilsService.getFilenameFromResponseHeaders(blob);
        link.download =
          filename ??
          `baskets--${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.xlsx`;

        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();

        this.isLoading = false;
      },

      error: async (error: HttpErrorResponse) => {
        const { title, message } = await this.errorService.handleError(error);

        this.isLoading = false;

        this.messageService.add({
          detail: message,
          key: 'baskets',
          severity: 'error',
          summary: title,
        });
      },
    });
  }

  private fetchNextPage(lazyLoadEvent: LazyLoadEvent) {
    this.isLoading = true;

    this.basketApi
      .getAll(
        UtilsService.fromLazyLoadEventToQueryUiDto(
          lazyLoadEvent,
          this.apiPageSize,
        ),
      )
      .subscribe({
        next: (data) => {
          this.baskets = data.rows;
          this.recordCount = data.count;

          this.isLoading = false;
        },

        error: (error: HttpErrorResponse) => {
          this.errorService.handleError(error);

          this.isLoading = false;
        },
      });
  }

  getCollectionTypeTagSeverity(type: CollectionType): 'info' | 'danger' | '' {
    return UtilsService.getCollectionTypeTagSeverity(type);
  }

  getProgramTypeTagSeverity(
    type: ProgramType,
  ): 'primary' | 'success' | 'warning' | '' {
    return UtilsService.getProgramTypeTagSeverity(type);
  }

  getStatusTagSeverity(
    statusCode: string,
  ): 'primary' | 'danger' | 'success' | 'warning' | '' {
    return UtilsService.getBasketStatusTagSeverity(statusCode);
  }

  onLazyLoadEvent(lazyLoadEvent: LazyLoadEvent) {
    this.isLoading = true;

    const queryParams =
      UtilsService.fromLazyLoadEventToQueryParams(lazyLoadEvent);

    this.router.navigate([], {
      queryParams,
      relativeTo: this.activatedRoute,
      replaceUrl: true,
    });
  }
}
